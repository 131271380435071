<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <v-row no-gutters>
      <span :style="errorMessage && !isDownloading ? 'color: #800000' : ''">
        {{
          isDownloading
            ? $t('remoteConnection.dialog.detailDownloading')
            : errorMessage &&
                (errorMessage === 'nerve_workload_cancelled' || errorMessage === 'nerve_workload_files_cancelled')
              ? $t('workloadDetail.workloadCreationCanceled')
              : errorMessage &&
                  errorMessage !== 'nerve_workload_cancelled' &&
                  errorMessage !== 'nerve_workload_files_cancelled'
                ? $t('workloadDetail.downloadError')
                : tableItem.releaseName
        }}
      </span>
      <v-tooltip
        v-if="
          type === 'docker-compose' &&
          ((tableItem.summarizedFileStatuses && tableItem.summarizedFileStatuses.failed !== 0) || tableItem.isFailed)
        "
        bottom
      >
        <template #activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-img
              :id="`iiotWorkloadDetailReleaseNameErrorIcon_${index}`"
              src="/img/warning.svg"
              max-height="17px"
              max-width="17px"
              class="ml-4 mt-1"
            />
          </div>
        </template>
        <span>
          {{ $t('errorMessages.WORKLOAD_SOME_DOCKER_IMG_DOWNLOAD_ERROR') }}
        </span>
      </v-tooltip>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    isDownloading() {
      return this.tableItem.isDownloading;
    },
    errorMessage() {
      return this.tableItem.errorMessage;
    },
  },
};
</script>
